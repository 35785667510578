<template>
  <div class="container">
    <p>
      <strong>{{title}}</strong>
    </p>
    <p>
      Hi! You've already spent
      <em>{{formattedTimeOnSite}}</em> on this site today. We strongly believe you should go back to whatever important task you need to get done 😊
    </p>
  </div>
</template>
<script>
export default {
  name: "Home",
};
</script>